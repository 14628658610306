import type { PassThrough } from '@primevue/core';
import type { DialogPassThroughOptions } from 'primevue/dialog';
import { mask } from './generics';
export const dialogPassthrough: PassThrough<DialogPassThroughOptions> = {
  root: {
    class:
      'bg-layer-3 rounded-xl overflow-hidden absolute top-2 sm:top-10 md:top-[5vh]',
  },
  header: {
    class: 'bg-layer-1 flex items-center',
  },
  title: {
    class: 'text-base font-bold text-xl',
  },
  content: { class: 'max-h-[99vh] md:max-h-[calc(85vh-60px)] overflow-auto' },
  closeButton: {
    class: 'bg-base',
  },
  mask,
  transition: {
    enterFromClass: 'opacity-0 scale-75 mask-active',
    enterActiveClass: 'transition-all duration-200 ease-out',
    leaveActiveClass: 'transition-all duration-200 ease-out',
    leaveToClass: 'opacity-0 scale-75 mask-active',
  },
};
